<template>
  <div class="v-stack h-stretch">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to delete this car?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div class="card light mn">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left required">Name:</label>
          <input type="text" v-model="car.name" />
          <label class="text-left required">Fuel Consumption per 100km:</label>
          <input type="text" v-model="car.consumption" />
          <label class="text-left required"
            >Depriciation value CZK per km:</label
          >
          <input type="text" v-model="car.useRatePerKm" />
          <div class="h-stack h-space-between">
            <div v-if="!id"></div>
            <button class="edit" v-if="id" @click.prevent="showDialog()">
              Delete
            </button>
            <button class="submit" @click.prevent="submit()">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      car: {
        name: "",
        consumption: 0,
        useRatePerKm: 0,
      },
    };
  },
  props: ["id"],
  methods: {
    ...mapActions(["addCar", "editCar", "getCar", "deleteCar"]),
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    remove() {
      this.dismiss();
      this.deleteCar(this.id)
        .then(() => {
          this.$router.go(-2);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showDialog() {
      this.$refs.overlay.classList.add("show");
    },
    submit() {
      if (this.id) {
        this.editCar(this.car)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.addCar(this.car)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getCar(this.id)
        .then((car) => {
          this.car = car;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
